import { projectFirestore } from "../../../../../firebase/config";

const change_family_in_db = async(old_family,new_family) => {
    const promises = []
    const docs = await projectFirestore.collection('Products').where('sub_category','==',old_family).get()
    const products = docs.docs.map(doc=>doc.data())
    console.log(products);
   
    for(const item of products){
        promises.push(
            projectFirestore.collection('Products').doc(item.makat)
            .set({
                sub_category:new_family
            },{merge:true})
        )
    }

    await Promise.all(promises)

}

const updates_subs_in_department = async(department) => {
    await projectFirestore.collection('Departments').doc(department.uid)
    .set({
        subs:department.subs
    },{merge:true})
}

const delete_total_family_in_db = async(family) => {
    const promises = []
    const docs = await projectFirestore.collection('Products').where('sub_category','==',family).get()
    const products = docs.docs.map(doc=>doc.data())
   
    for(const item of products){
        promises.push(
            projectFirestore.collection('Products').doc(item.makat)
            .set({
                sub_category:null
            },{merge:true})
        )
    }

    await Promise.all(promises)
}

export{
    change_family_in_db,
    updates_subs_in_department,
    delete_total_family_in_db,
}