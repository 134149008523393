<template>
    <div class="departments">
        <div class="tool-bar">
            <el-button type="warning" class="tool-btn" @click="reorder_family = false" v-if="reorder_family">חזור למחלקות</el-button>
            <el-button type="primary" class="tool-btn" @click="add_department">הוסף מחלקה</el-button>
        </div>
        <div class="departments-grid" v-show="!reorder_family">
            <template v-for="(department,i) in departments" :key="department.uid">
                <div class="department">
                    <p class="dep-order">{{i + 1}}</p>
                    <p class="dep-name">{{department.name}}</p>
                    <i class="material-icons" style="cursor:pointer;" v-if="show_subs != department.name" @click="show_subs = department.name">arrow_downward</i>
                    <i class="material-icons" style="cursor:pointer;" v-if="show_subs == department.name" @click="show_subs = ''">arrow_upward</i>
                </div>
                <div class="dep-subs" v-if="show_subs == department.name">
                    <div class="dep-tools">
                        <el-button type="primary" class="dep-btn" @click="add_family(department.uid)">הוסף משפחה</el-button>
                        <el-button type="info" class="dep-btn" @click="update_depratment_name(department)">עדכן מחלקה</el-button>
                        <el-button type="danger" class="dep-btn">מחק מחלקה</el-button>
                    </div>
                    <template v-for="(sub,j) in department.subs" :key="sub">
                        <div class="dep-sub">
                            <div class="up-down-sub">
                                <i class="material-icons" style="cursor:pointer;" @click="move_sub('up', department, j)" v-if="j > 0">expand_less</i>
                                <i class="material-icons" style="cursor:pointer;" @click="move_sub('down', department, j)" v-if="j< department.subs.length - 1">expand_more</i>
                            </div>
                            <p style="width:50%;">{{sub}}</p>
                            <p class="sub-items" @click="open_reorder(sub)">פריטים</p>
                            <p class="sub-edit" @click="update_sub_depratment_name(department, sub)">ערוך</p>
                            <p class="sub-delete" @click="handle_delete_family(department,sub)">מחק</p>
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div class="family-order" v-if="reorder_family" id="family-order">
            <template v-for="(item, i) in family_products" :key="item.makat">
                <div class="item-box" :id="`${item.makat}`">
                    <div class="item-status">
                        {{item.status}}
                    </div>
                    <div class="item-order">
                        <p>מיקום: {{item.order}}</p>
                    </div>
                    <div class="item-web">
                        <p v-if="item.showOnWeb == 'Y'" style="color:var(--success)">Y - מוצג</p>
                        <p v-if="item.showOnWeb == 'H'" style="color:var(--danger)">H - מוסתר</p>
                        <p v-if="item.showOnWeb == 'N'" style="color:var(--warning)">N - חסר במלאי</p>
                    </div>
                    <div class="item-image">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="item-data">
                        <p>{{item.name}}</p>
                        <p>{{item.makat}}</p>
                    </div>
                    <div class="change-order">
                        <el-input placeholder="מיקום המוצר" v-model="item.order" style="width: 70%;" :input-style="{'text-align':'center'}" type="number"></el-input>
                        <el-button type="success" @click="update_order(item.order, i, item)">עדכן מיקום</el-button>
                    </div>
                </div>
            </template>
        </div>
        <DeleteFamilyDialog @close="show_delete_family_dialog=false" :department="selected_dep"
         :sub="selected_sub" v-if="show_delete_family_dialog" 
         @change_family="handle_change_family"
         @total_delete_family="handle_total_delete_family"
         />
    </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref } from '@vue/runtime-core'
import Swal from 'sweetalert2'
import { projectFirestore, projectFunctions } from '../../../firebase/config';
import { slide_pop_error, slide_pop_successs,alert } from '../../../Methods/Msgs';
import {change_family_in_db,updates_subs_in_department,delete_total_family_in_db} from './Departments/scripts/scripts'

export default {
components:{
    DeleteFamilyDialog:defineAsyncComponent(() => import('./Departments/DeleteFamilyDialog.vue'))
},
setup(){    
    const departments = ref([]);
    const show_subs = ref('');
    const reorder_family = ref(false);

    const family_products = ref([]);
    const selected_dep = ref(null)
    const selected_sub = ref('')
    const show_delete_family_dialog = ref(false)
    
    const handle_total_delete_family = async() => {
        await delete_total_family_in_db(selected_sub.value)
        selected_dep.value.subs = selected_dep.value.subs.filter(sub => sub != selected_sub.value)
        await updates_subs_in_department(selected_dep.value)
        await get_departments()
        alert('success','הצלחה',
        `המשפחה ${selected_sub.value} נמחקה, כל המוצרים שהיו שייכים למשפחה זו משוחררים ממנה`)
        .then(()=>{
            selected_dep.value = null
            selected_sub.value = false
            show_delete_family_dialog.value = false
        })

    }

    const handle_change_family = async(new_family) => {
        await change_family_in_db(selected_sub.value,new_family)
        selected_dep.value.subs = selected_dep.value.subs.filter(sub => sub != selected_sub.value)
        await updates_subs_in_department(selected_dep.value)
        await get_departments()
        alert('success','הצלחה',
        `כל מוצרי ${selected_sub.value} עברו למחלקה ${new_family}`)
        .then(()=>{
            selected_dep.value = null
            selected_sub.value = false
            show_delete_family_dialog.value = false
        })
    }

    const handle_delete_family = (department,sub) => {
        selected_dep.value = JSON.parse(JSON.stringify(department));
        selected_sub.value = sub
        show_delete_family_dialog.value = true
    }
    
    const get_departments = async () => {
        const docs = await projectFirestore.collection('Departments').get()
        if(!docs.empty){
            departments.value = docs.docs.map(doc => doc.data());
            departments.value = departments.value.sort((aa, bb) => {
                return aa.order - bb.order
            })
        }
    }

    const add_department = () => {
        Swal.fire({
            icon: 'question',
            title: 'בחר שם למחלקה',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'ביטול'
        }).then(async (res) => {
            if(res.isConfirmed && res.value.length > 0){
                const op = await projectFunctions.httpsCallable('add_department')({name: res.value})
                if(op){
                    slide_pop_successs('מחלקה נוצרה בהצלחה!')
                    get_departments();
                }
            }
        })
    }

    const add_family = (uid) => {
        Swal.fire({
            icon: 'question',
            title: 'בחר שם למשפחה',
            input: 'text',
            showCancelButton: true,
            cancelButtonText: 'ביטול'
        }).then(async (res) => {
            if(res.isConfirmed && res.value.length > 0){
                const op = await projectFunctions.httpsCallable('add_family')({name: res.value, uid: uid})
                if(op){
                    slide_pop_successs('מחלקה נוצרה בהצלחה!')
                    get_departments();
                }
            }
        })
    }

    const move_sub = (direction, department, index) => {
        if(direction == 'down'){
            department.subs.splice(index + 1, 0, department.subs.splice(index, 1)[0])
            projectFunctions.httpsCallable('save_family_order')({uid: department.uid, subs: department.subs})
        }
        if(direction == 'up'){
            department.subs.splice(index - 1, 0, department.subs.splice(index, 1)[0])
            projectFunctions.httpsCallable('save_family_order')({uid: department.uid, subs: department.subs})
        }
    }

    const update_depratment_name = (department) => {
        Swal.fire({
            icon: 'question',
            title: 'בחר שם חדש למחלקה',
            input: 'text',
            inputPlaceholder: department.name,
            showCancelButton: true,
            cancelButtonText: 'ביטול'
        }).then(async (res) => {
            if(res.isConfirmed && res.value.length > 2){
                await projectFunctions.httpsCallable('update_department_name')({dep_uid: department.uid, dep_name: res.value});
                department.name = res.value
                slide_pop_successs('המחלקה עודכנה בהצלחה!')
            }
        })
    }
    
    const update_sub_depratment_name = (department, sub) => {
        var old_sub = sub;
        Swal.fire({
            icon: 'question',
            title: 'בחר שם חדש למשפחה',
            input: 'text',
            inputPlaceholder: sub,
            showCancelButton: true,
            cancelButtonText: 'ביטול',
            showDenyButton: true,
            confirmButtonText: 'שנה שם',
            denyButtonText: 'מחק משפחה'
        }).then(async (res) => {
            if(res.isConfirmed && res.value.length > 2){
                let inx = department.subs.findIndex(_sub => {
                    return _sub == sub
                })
                if(inx != -1){
                    department.subs[inx] = res.value
                    await projectFunctions.httpsCallable('update_sub_department_name')({dep_uid: department.uid, subs: department.subs, old: old_sub});
                    slide_pop_successs('המשפחה עודכנה בהצלחה!')
                }else{
                    slide_pop_error('שגיאה, אנא פנה לתמיכה טכנית.')
                }

            }
            if(res.isDenied){
                Swal.fire({
                    icon: 'warning',
                    title: 'האם אתה בטוח?',
                    text: 'מחיקת משפחה תמחק את המשפחה מכלל המוצרים אשר משוייכים אליה.',
                    showCancelButton: true,
                    cancelButtonText: 'אל תמחק',
                    confirmButtonText: 'מחק'
                }).then(async (res) => {
                    if(res.isConfirmed){
                        let inx = department.subs.findIndex(_sub => {
                            return _sub == sub
                        })
                        if(inx != -1){
                            department.subs.splice(inx, 1);
                            await projectFunctions.httpsCallable('delete_sub_department')({dep_uid: department.uid, subs: department.subs, old: old_sub})
                        }
                    }
                })
            }
        })
    }

    const open_reorder = async (sub) => {
        const docs = await projectFirestore.collection('Products').where('sub_category', '==', sub).get()
        if(!docs.empty){
            family_products.value = docs.docs.map(doc => doc.data());
            family_products.value.sort((aa, bb) => {
                return aa.order - bb.order
            })
            reorder_family.value = true;
        }else{
            Swal.fire({
                icon: 'info',
                title: 'משפחה ריקה',
                text: 'שייך מוצרים למשפחה תחילה.'
            }).then(() => {
                return
            })
        }
    }

    const update_order = (target, original, item) => {
        if(target > family_products.value.length - 1){
            target = family_products.value.length - 1
            item.order = target
        }
        if(target > original){
            family_products.value[target].order = Number(target) - 1
        }else{
            family_products.value[target].order = Number(target) + 1
        }
        
        family_products.value.sort((aa, bb) => {
            return aa.order - bb.order
        })
        setTimeout(async () => {
            for(let i = 0; i <family_products.value.length; i++){
            family_products.value[i].order = i
            }
            await projectFunctions.httpsCallable('update_family_order')({items: family_products.value})
            slide_pop_successs('סידור משפחה עודכן בשרת')
        }, 300);

    }
    
    onMounted(() => {
        get_departments();
    })

    return{
        add_department, departments, show_subs, add_family, move_sub, update_depratment_name,
        update_sub_depratment_name, reorder_family, family_products, open_reorder,update_order,handle_delete_family,
        selected_dep,selected_sub,show_delete_family_dialog,handle_change_family,handle_total_delete_family
    }
}
}
</script>

<style scoped>
.departments{
    width: 100%;
    height: 100%;
}
.tool-bar{
    width: 100%;
    height: 80px;
    background: var(--alert-purple);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
}
.departments-grid{
    width: 100%;
    height: calc(100% - 80px);
    padding: 10px 0 0 0;
    overflow-y: auto;
}
.family-order{
    width: 100%;
    height: calc(100% - 80px);
    padding: 10px 0 0 0;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-auto-rows: 170px;
    gap: 5px;
}
.item-box{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--secondary);
    border-radius: 5px;
}
.item-image{
    margin-top: 5px;
    min-width: 120px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 5px;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.item-data{
    width: 100%;
    height: calc(100% - 5px - 80px - 40px);
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    text-align: center;
    color: white;
}
.item-status{
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background: var(--alert-purple);
    color: var(--warning);
    border-radius: 5px;
}
.item-order{
    position: absolute;
    top: 5px;
    right: 5px;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background: var(--alert-purple);
    color: var(--light);
    border-radius: 5px;
}
.item-web{
    position: absolute;
    top: 40px;
    left: 5px;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background: var(--alert-purple);
    color: var(--warning);
    border-radius: 5px;
}
.change-order{
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.department{
    width: 450px;
    height: 50px;
    border-radius: 5px;
    font-size: 21px;
    color: var(--light);
    background: rgba(47, 33, 110, 0.692);
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
}
.tool-btn{
    height: 100%;
    margin-left: 8px;
}
.dep-name{
    text-align: center;
    width: calc(100% - 100px);
}
.dep-order{
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--yellow);
}
.dep-subs{
    width: 450px;
    height: fit-content;
    padding: 5px 0 5px 0;
    transition: 0.25s;
}
.dep-tools{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
}
.dep-btn{
    margin: 0;
}
.dep-sub{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--secondary);
    margin-bottom: 5px;
    border-radius: 5px;
    color: var(--light);
}
.up-down-sub{
    width: 80px;
    height: 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.sub-items{
    padding: 0 5px 0 5px;
    background: var(--purple);
    cursor: pointer;
    color: var(--light);
    border-radius: 3px;
    margin-left: 5px;
}
.sub-edit,.sub-delete{
    padding: 0 5px 0 5px;
    background: var(--danger);
    cursor: pointer;
    color: var(--light);
    border-radius: 3px;
}
.sub-delete{
    margin: 0 5px;
}
@media only screen and (max-width: 660px) {
    .department{
        width: 100%;
    }
    .departments-grid{
        width: 100%;
        height: fit-content;
    }
    .dep-subs{
        width: 100%;
        padding: 5px 0 5px 0;
    }
    .family-order{
        grid-template-columns: 100%;
    }
}
</style>